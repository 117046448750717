<template>
    <div>
        <div class="demo-spin-article">
            <div>
                <div class="col-md-12 mb-3">
                    <label class="form-label col-label required tw-text-xs">{{
                        $t('workingHours.employeeName')
                    }}</label>
                    <Select
                        v-model="model.employee_id"
                        filterable
                        remote
                        :remote-method="searchEmployee"
                        :loading="loading"
                        class="from-label"
                        :class="{
                            'is-invalid': errors.has('employee_id')
                        }"
                    >
                        <Option
                            v-for="(emp, index) in employeeProfile"
                            :value="emp.employee_id"
                            :key="index"
                        >
                            {{
                                emp.card_id +
                                '-' +
                                emp.employee_name_kh +
                                '-' +
                                emp.employee_name_en
                            }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('employee_id')"
                    >
                        {{ errors.first('employee_id') }}
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="form-label col-label required">{{
                            $t('workingHours.fromTime')
                        }}</label>
                        <time-picker
                            v-model="model.from_time"
                            type="time"
                            placeholder="HH:mm"
                            style="width: 270px"
                            format="HH:mm"
                            confirm
                            :class="{
                                'is-invalid': errors.has('time_from')
                            }"
                        >
                        </time-picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('time_from')"
                        >
                            {{ errors.first('time_from') }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label col-label required">{{
                            $t('workingHours.toTime')
                        }}</label>
                        <time-picker
                            v-model="model.to_time"
                            type="time"
                            placeholder="HH:mm"
                            style="width: 280px"
                            format="HH:mm"
                            confirm
                            :class="{
                                'is-invalid': errors.has('time_to')
                            }"
                        ></time-picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('time_to')"
                        >
                            {{ errors.first('time_to') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="form-label col-label required">{{
                            $t('workingHours.totalHour')
                        }}</label>
                        <input
                            v-model="model.total_hour"
                            type="text"
                            class="form-control"
                            style="width: 270px"
                            placeholder="Input Working Hours"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('total_hour')"
                        >
                            {{ errors.first('total_hour') }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label col-label required">{{
                            $t('workingHours.workingDate')
                        }}</label>
                        <DatePicker
                            type="date"
                            v-model="model.working_date"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            @on-change="workingDate"
                        ></DatePicker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('working_date')"
                        >
                            {{ errors.first('working_date') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="form-label col-label required">{{
                            $t('workingHours.cycleYear')
                        }}</label>
                        <date-Picker
                            :value="model.cycle_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            style="width: 270px"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('cycle_year')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first('cycle_year') }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label col-label required">{{
                            $t('workingHours.cycleMonth')
                        }}</label>
                        <date-Picker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width: 280px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-label form-label tw-text-xs">
                        {{ $t('itemOvertime.description') }}
                    </label>
                    <div>
                        <textarea
                            v-model="model.remark"
                            class="form-control"
                            rows="4"
                        >
                        </textarea>
                    </div>
                </div>
                <div>
                    <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                        <ts-button
                            @click.prevent="() => $emit('cancel')"
                            class="btn-gray"
                        >
                            {{ $t('cancel') }}
                        </ts-button>
                        <ts-button
                            v-if="!isUpdate"
                            color="primary"
                            outline
                            :waiting="waiting_new"
                            :disabled="waiting_new || waiting"
                            @click.prevent="onSaveAddNew"
                        >
                            {{ $t('saveAddNew') }}
                        </ts-button>
                        <ts-button
                            v-if="!isUpdate"
                            color="primary"
                            :waiting="waiting"
                            :disabled="waiting_new || waiting"
                            @click.prevent="onSave"
                        >
                            {{ $t('save') }}
                        </ts-button>
                        <ts-button
                            v-if="isUpdate"
                            color="primary"
                            :waiting="waiting"
                            :disabled="waiting_new || waiting"
                            @click.prevent="onUpdate"
                        >
                            {{ $t('update') }}
                        </ts-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { debounce, isEmpty } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'working-hour-form-create',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            employeeProfile: [],
            model: {
                employee_id: null,
                from_time: null,
                to_time: null,
                total_hour: null,
                working_date: null,
                remark: null,
                cycle_year: null,
                cycle_month: null
            }
        }
    },
    computed: {
        ...mapState('payroll/workingHours', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    created () {
        this.fetchResource({
            fnName: 'employeeProfile'
        })
        this.setEditData()
    },
    methods: {
        ...mapActions('payroll/workingHours', ['formViewModel']),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.formViewModel({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        searchEmployee: debounce(async function (query) {
            var avoidSearch = this.employeeProfile.find(
                emp =>
                    emp.card_id +
                        '-' +
                        emp.employee_name_kh +
                        '-' +
                        emp.employee_name_en ==
                    query.trim()
            )
            if (avoidSearch) return
            this.fetchResource({
                fnName: 'employeeProfile',
                emp_search: query
            })
        }, 500),
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/workingHours/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch(
                    'payroll/workingHours/store',
                    Object.assign({}, this.model)
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/workingHours/update', {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData () {
            this.model.employee_id = this.edit_data.employee_id
            this.model.from_time = this.edit_data.from_time
            this.model.to_time = this.edit_data.to_time
            this.model.total_hour = this.edit_data.total_hour
            this.model.working_date = this.edit_data.working_date
            this.model.remark = this.edit_data.remark
            this.model.cycle_year = this.edit_data.cycle_year
            this.model.cycle_month = this.edit_data.cycle_month
        },
        cycleYearChange (value) {
            this.model.cycle_year = value
        },
        cycleMonthChange (value) {
            this.model.cycle_month = value
        },
        workingDate (value) {
            this.model.working_date = value
        },

        clearInput () {
            this.errors = new Errors()
            this.model.employee_id = null
            this.model.working_date = null
            this.model.from_time = null
            this.model.to_time = null
            this.model.total_hour = null
            this.model.remark = null
            this.model.cycle_year = null
            this.model.cycle_month = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'WORKING HOURS',
                desc: not.text
            })
        }
    }
}
</script>
