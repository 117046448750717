<template>
    <div class="demo-spin-article">
        <div class="row mb-3">
            <div class="col-md-6">
                <label class="form-label col-label required">{{
                    $t('workingHours.cycleYear')
                }}</label>
                <date-Picker
                    :value="model.cycle_year"
                    type="year"
                    format="yyyy"
                    placeholder="Select Year"
                    style="width: 230px"
                    @on-change="cycleYearChange"
                    :class="{
                        'is-invalid': errors.has('cycle_year')
                    }"
                >
                </date-Picker>
            </div>
            <div class="col-md-6">
                <label class="form-label col-label required">{{
                    $t('workingHours.cycleMonth')
                }}</label>
                <date-Picker
                    :value="model.cycle_month"
                    type="month"
                    placeholder="Select Month"
                    style="width: 230px"
                    format="MM"
                    @on-change="cycleMonthChange"
                    :class="{
                        'is-invalid': errors.has('cycle_month')
                    }"
                >
                </date-Picker>
            </div>
        </div>
        <div class="row mb-3">
            <Checkbox v-model="model.override">{{
                $t('workingHours.override')
            }}</Checkbox>
        </div>
        <div class="mb-3">
            <div class="col-md-12 mb-3">
                <label
                    for="file"
                    class="tw-border tw-p-2 tw-rounded-md tw-cursor-pointer"
                    >Browse File</label
                >
                <input
                    type="file"
                    ref="file"
                    id="file"
                    class="tw-hidden"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="onFileChange"
                />
                <div class="validate" v-if="errors.has('file')">
                    {{ errors.first('file') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                {{ display_name }}
            </div>
        </div>
        <div>
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}
                </ts-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'

export default {
    name: 'import-working-hours-form',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            display_name: null,
            file: undefined,
            model: {
                cycle_year: null,
                cycle_month: null,
                override: false,
            }
        }
    },
    methods: {
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('cycle_year', this.model.cycle_year)
            formData.append('cycle_month', this.model.cycle_month)
            formData.append('override', this.model.override)
            this.$store
                .dispatch('payroll/workingHours/importExcel', formData)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onFileChange (e) {
            this.display_name = null
            this.file = e.target.files[0]
            this.display_name = this.file ? this.file.name : null
        },
        cycleYearChange (value) {
            this.model.cycle_year = value
        },
        cycleMonthChange (value) {
            this.model.cycle_month = value
        },
        clearInput () {
            this.errors = new Errors()
            this.display_name = null
            this.model.cycle_year = null
            this.model.cycle_month = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'WORKING HOURS',
                desc: not.text
            })
        }
    }
}
</script>
